import React, { Suspense } from "react";
import { render } from "react-dom";
import App from "./App";
//import {store} from './redux/store'
import { Provider, ReactReduxContext } from "react-redux";

import configureStore from "./redux/store";
import { history } from "./util/history";
import { ToastProvider } from "react-toast-notifications";

const store = configureStore(/* provide initial state if any */);

render(
  <ToastProvider autoDismiss autoDismissTimeout={6000} placement="bottom-right">
    <Suspense fallback={<div>Loading...</div>}>
      <Provider store={store} context={ReactReduxContext}>
        <App history={history} context={ReactReduxContext} />
      </Provider>
    </Suspense>
  </ToastProvider>,
  document.getElementById("root")
);
